import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
	AppBar,
	Box,
	Button,
	Container,
	Dialog,
	Grid,
	IconButton,
	Menu,
	MenuItem,
	ThemeProvider,
	Toolbar,
	Typography,
	createTheme,
	styled,
} from '@mui/material';
import { Landing } from './pages/landing/landing';
import TriageMintLogo from './assets/triage-logo-mint-white.svg';
import { MenuIcon, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { Features } from './pages/features/features';
import FooterCardBg from './assets/footer-card-bg.png';

import InstagramIcon from './assets/instagram-outline-icon.svg';
import MailIcon from './assets/mail-outline-icon.svg';
import LinkedInIcon from './assets/linkedIn-outline-icon.svg';
import GithubIcon from './assets/github-icon.svg';
import { TOS } from './pages/landing/tos';
import { PrivacyPolicy } from './pages/landing/privacy';
import { SelfHosting } from './pages/self-hosting/self-hosting';
import { CloudHosting } from './pages/cloud-hosting/cloud-hosting';
import { Services } from './pages/services/services';
import { Support } from './pages/support/support';
import { CloudTrial } from './pages/self-hosting/cloud-trial';
import { TableDetail } from './pages/self-hosting/table-detail';

const theme = createTheme({
	typography: {
		fontFamily: ['Mont', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
	},
});

const pages = ['Home', 'Features', 'Self-Hosting', 'Cloud-Hosting', 'Services', 'Support'];

export const ChipButton = styled(Button)(({ theme }) => ({
	background: '#D0FFD6',
	borderRadius: 60,
	paddingLeft: '20px',
	paddingRight: '20px',
	paddingTop: 8,
	'&:hover': {
		background: '#e8ffeb',
	},
	'& .MuiTouchRipple-root span': {
		backgroundColor: '#68806b',
	},
}));

export const OutlinedChipButton = styled(ChipButton)(() => ({
	background: 'unset',
	border: '1.5px solid #FFF',
	color: '#FFF',
	height: '42px',
	boxSizing: 'border-box',
	'&:hover': {
		background: '#e8ffeb1a',
	},
	'& .MuiTouchRipple-root span': {
		backgroundColor: '#68806b',
	},
}));

const GradientDivider = styled(Box)(() => ({
	width: '100%',
	height: '1px',
	background:
		'radial-gradient(circle at 50%, rgba(208, 255, 214, 0.7) 0%, rgba(208, 255, 214, 0) 90%)',
	marginTop: '170px',
	marginBottom: '112px',
}));

function App() {
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [legalsOpen, setLegalsOpen] = useState(false);
	const [legalsType, setLegalsType] = useState('');
	const [showTopLight, setShowTopLight] = useState(false);
	const [numberCommits, setNumberCommits] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);

		if (!location.pathname.includes('features')) {
			setShowTopLight(location.pathname.split(/\/(?=.)/).length > 1);
		} else {
			setShowTopLight(false);
		}

		getGithubCommits();
	}, [location]);

	const getGithubCommits = () => {
		const username = 'rayandabbagh';
		const repoName = 'triage.ai.backend';
		const token = process.env.REACT_APP_GITHUB_TOKEN;

		fetch(`https://api.github.com/repos/${username}/${repoName}/commits`, {
			headers: {
				Authorization: `token ${token}`,
			},
		})
			.then(response => response.json())
			.then(commits => {
				const numberOfCommits = commits.length;
				setNumberCommits(numberOfCommits);
			})
			.catch(error => {
				console.error('Error fetching commits:', error);
			});
	};

	const handleOpenNavMenu = event => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const goToPage = page => {
		if (page && typeof page === 'string') {
			if (page === 'Home') {
				navigate('/');
			} else {
				// document.getElementById(page).scrollIntoView();
				navigate('/' + page.toLowerCase());
			}
		}
	};

	const navigateGetStarted = () => {
		navigate('/self-hosting');
	};

	const handleLegalsOpen = type => {
		setLegalsOpen(true);
		setLegalsType(type);
	};

	const handleLegalsClose = () => {
		setLegalsOpen(false);
		setLegalsType('');
	};

	const getActiveClassname = page => {
		const pathname = location.pathname.replace('/', '');

		return pathname === page.toLowerCase() || (pathname === '' && page === 'Home')
			? 'active-menu-option'
			: 'menu-option';
	};

	return (
		<ThemeProvider theme={theme}>
			<div className="App">
				<Box sx={{ width: '100%', height: '100%', backgroundColor: '#050F04' }}>
					{showTopLight && (
						<Box
							sx={{
								width: '40vw',
								height: '40vw',
								background: 'rgba(204, 254, 212, 0.4)',
								position: 'absolute',
								top: '-20vw',
								left: '0',
								right: '0',
								marginX: 'auto',
								filter: { xs: 'blur(15vh)', md: 'blur(25vh)' },
								zIndex: 0,
							}}
						/>
					)}

					<AppBar
						position="static"
						sx={{ background: 'unset', boxShadow: 'unset' }}
					>
						<Container>
							<Toolbar
								disableGutters
								sx={{ height: '72px', justifyContent: { xs: 'space-between', md: 'center' } }}
							>
								<Box
									sx={{ cursor: 'pointer' }}
									onClick={() => navigate('/')}
								>
									{/* <Box sx={{ width: '30%' }}> */}
									<img
										src={TriageMintLogo}
										alt="Triage Logo"
										style={{ display: 'block', height: '40px' }}
									/>
								</Box>

								<Box
									sx={{
										alignItems: 'center',
										justifyContent: 'center',
										flexGrow: 1,
										display: { xs: 'none', md: 'flex' },
										gap: { xs: 0, md: '2px', lg: '24px' },
									}}
								>
									{pages.map(page => (
										<Button
											key={page}
											onClick={() => goToPage(page)}
											className={getActiveClassname(page)}
											sx={{ my: 2, color: 'white', display: 'block' }}
										>
											<Typography
												variant="subtitle1"
												sx={{
													display: { xs: 'none', lg: 'block' },
													fontWeight: 600,
													textTransform: 'none',
													width: '100%',
												}}
											>
												{page}
											</Typography>

											<Typography
												variant="subtitle2"
												sx={{
													display: { xs: 'block', lg: 'none' },
													fontWeight: 600,
													textTransform: 'none',
													width: '100%',
												}}
											>
												{page}
											</Typography>
										</Button>
									))}

									<a
										style={{
											color: '#FFF',
											textDecoration: 'none',
											display: 'flex',
											alignItems: 'center',
										}}
										href="https://github.com/rayandabbagh/triage.ai.backend"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											src={GithubIcon}
											alt="Github Icon"
											style={{ width: 20, height: 20, marginRight: 4, marginBottom: 2 }}
										/>
										<Typography
											variant="subtitle1"
											sx={{
												display: { xs: 'none', lg: 'block' },
												fontWeight: 600,
												textTransform: 'none',
												width: '100%',
											}}
										>
											{numberCommits}
										</Typography>

										<Typography
											variant="subtitle2"
											sx={{
												display: { xs: 'block', lg: 'none' },
												fontWeight: 600,
												textTransform: 'none',
												width: '100%',
											}}
										>
											{numberCommits}
										</Typography>
									</a>
								</Box>

								<Box
									sx={{
										// width: { xs: '70%', md: '30%' },
										display: 'flex',
										flexShrink: 0,
										alignItems: 'center',
										justifyContent: 'flex-end',
									}}
								>
									<ChipButton onClick={navigateGetStarted}>
										<Typography
											variant="subtitle1"
											sx={{
												color: '#000',
												fontWeight: 700,
												textTransform: 'none',
												width: 'fit-content',
											}}
										>
											Get Started
										</Typography>
									</ChipButton>

									<Box sx={{ display: { xs: 'flex', md: 'none' } }}>
										<IconButton
											size="large"
											aria-label="account of current user"
											aria-controls="menu-appbar"
											aria-haspopup="true"
											onClick={handleOpenNavMenu}
											color="inherit"
										>
											<MenuIcon />
										</IconButton>
										<Menu
											id="menu-appbar"
											anchorEl={anchorElNav}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}
											keepMounted
											transformOrigin={{
												vertical: 'top',
												horizontal: 'left',
											}}
											open={Boolean(anchorElNav)}
											onClose={handleCloseNavMenu}
											sx={{
												display: { xs: 'block', md: 'none' },
											}}
										>
											{pages.map(page => (
												<MenuItem
													key={page}
													onClick={() => {
														handleCloseNavMenu();
														goToPage(page);
													}}
												>
													<Typography textAlign="center">{page}</Typography>
												</MenuItem>
											))}
										</Menu>
									</Box>
								</Box>
							</Toolbar>
						</Container>
					</AppBar>
				</Box>

				<Routes>
					<Route
						path="/"
						exact
						element={<Landing />}
						// element={<Home />}
						// element={isAuth ? <Navigate to="/build" /> : <ComingSoon />}
					/>

					<Route
						path="/features"
						element={<Features />}
					/>

					<Route
						path="/self-hosting"
						element={<SelfHosting />}
					/>

					<Route
						path="/cloud-trial"
						element={<CloudTrial />}
					/>

					<Route
						path="/table-detail"
						element={<TableDetail />}
					/>

					<Route
						path="/cloud-hosting"
						element={<CloudHosting />}
					/>

					<Route
						path="/services"
						element={<Services />}
					/>

					<Route
						path="/support"
						element={<Support />}
					/>
				</Routes>

				<Container maxWidth="lg">
					<GradientDivider sx={{ mb: { xs: '70px' }, mt: { xs: '70px' } }} />

					<footer
						style={{
							width: '100%',
						}}
					>
						<Grid
							container
							spacing={{ xs: 6, md: 8, lg: 2 }}
						>
							<Grid
								xs={12}
								md={4}
								lg={4}
								item
							>
								<Box
									sx={{
										width: { xs: '100%', lg: '320px' },
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										color: '#FFF',
										textAlign: 'left',
									}}
								>
									<img
										src={TriageMintLogo}
										alt="Triage Logo"
										style={{ width: '50%', maxWidth: '200px', marginBottom: '30px' }}
									/>
									<Typography variant="subtitle1">
										Customer support platforms are just...{' '}
										<span
											style={{
												color: '#D0FFD6',
												textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
											}}
										>
											<i>ugh!</i>
										</span>{' '}
										<br />
										<br />
										Either they're stuck in the past, overly complicated, or unnecessarily expensive. <br />
										<br />
										Let's change the game.
									</Typography>

									<Box sx={{ display: 'flex', alignItems: 'center', ml: '-8px', mt: 2 }}>
										<IconButton
											aria-label="instagram"
											href="https://www.instagram.com/triage.ai"
											target="_blank"
											rel="noopener noreferrer"
											sx={{
												'&:hover img': {
													filter: 'sepia(1) saturate(3) hue-rotate(75deg)',
												},
											}}
										>
											<img
												src={InstagramIcon}
												alt="Instagram"
											/>
										</IconButton>

										<IconButton
											aria-label="email"
											href="mailto:support@triage-ai.com?cc=shivam.p36181@gmail.com,rayan.dabbagh@gmail.com&subject=[Triage AI Inquiry] *Please provide details about the purpose of your inquiry*"
											target="_blank"
											rel="noopener noreferrer"
											sx={{
												'&:hover img': {
													filter: 'sepia(1) saturate(3) hue-rotate(75deg)',
												},
											}}
										>
											<img
												src={MailIcon}
												alt="Email"
											/>
										</IconButton>

										<IconButton
											aria-label="linked in"
											href="https://www.linkedin.com/company/triageaii"
											target="_blank"
											rel="noopener noreferrer"
											sx={{
												'&:hover img': {
													filter: 'sepia(1) saturate(3) hue-rotate(75deg)',
												},
											}}
										>
											<img
												src={LinkedInIcon}
												alt="LinkedIn"
											/>
										</IconButton>
									</Box>
								</Box>
							</Grid>

							<Grid
								xs={12}
								md={8}
								lg={4}
								item
								sx={{ display: 'flex', justifyContent: { xs: 'unset', md: 'center', lg: 'unset' } }}
							>
								<Box
									sx={{
										width: '100%',
										display: 'flex',
										justifyContent: { xs: 'flex-start', md: 'center' },
										gap: 10,
										pt: 2,
									}}
								>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											color: '#FFF',
											gap: '26px',
										}}
									>
										<Typography
											variant="subtitle1"
											sx={{ color: '#FFF', fontWeight: 600 }}
										>
											Navigation
										</Typography>

										{pages.slice(0, 3).map(page => (
											<Button
												key={page}
												variant="text"
												disableRipple
												href={'#' + page}
												sx={{
													ml: '-8px',
													textTransform: 'none',
													minWidth: 'unset',
													'&:hover': {
														background: '#e8ffeb0d',
													},
												}}
											>
												<Typography
													variant="subtitle2"
													sx={{ color: 'rgba(236, 255, 239, 0.6)', fontWeight: 600 }}
												>
													{page}
												</Typography>
											</Button>
										))}
									</Box>

									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											color: '#FFF',
											gap: '26px',
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{ color: '#FFF', fontWeight: 600, visibility: 'hidden' }}
										>
											-
										</Typography>

										{pages.slice(3, 6).map(page => (
											<Button
												key={page}
												variant="text"
												disableRipple
												href={'#' + page}
												sx={{
													ml: '-8px',
													textTransform: 'none',
													minWidth: 'unset',
													'&:hover': {
														background: '#e8ffeb0d',
													},
												}}
											>
												<Typography
													variant="subtitle2"
													sx={{ color: 'rgba(236, 255, 239, 0.6)', fontWeight: 600 }}
												>
													{page}
												</Typography>
											</Button>
										))}
									</Box>
								</Box>
							</Grid>

							{/* <Grid
								xs={6}
								md={4}
								lg={2}
								item
								sx={{ display: 'flex', justifyContent: { xs: 'unset', md: 'center', lg: 'unset' } }}
							>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										color: '#FFF',
										gap: '26px',
									}}
								>
									<Typography
										variant="subtitle2"
										sx={{ color: '#FFF', fontWeight: 600 }}
									>
										Legals
									</Typography>

									<Button
										variant="text"
										disableRipple
										sx={{
											ml: '-8px',
											textTransform: 'none',
											minWidth: 'unset',
											'&:hover': {
												background: '#e8ffeb0d',
											},
										}}
										onClick={() => handleLegalsOpen('terms')}
									>
										<Typography
											variant="subtitle2"
											sx={{ color: 'rgba(236, 255, 239, 0.6)', fontWeight: 600 }}
										>
											Terms of Services
										</Typography>
									</Button>

									<Button
										variant="text"
										disableRipple
										sx={{
											ml: '-8px',
											textTransform: 'none',
											minWidth: 'unset',
											'&:hover': {
												background: '#e8ffeb0d',
											},
										}}
										onClick={() => handleLegalsOpen('privacy')}
									>
										<Typography
											variant="subtitle2"
											sx={{ color: 'rgba(236, 255, 239, 0.6)', fontWeight: 600 }}
										>
											Privacy Policy
										</Typography>
									</Button>
								</Box>
							</Grid> */}

							<Grid
								xs={12}
								md={6}
								lg={4}
								item
								sx={{ display: 'flex', justifyContent: { xs: 'flex-start', lg: 'flex-end' } }}
							>
								<a
									href="mailto:support@triage-ai.com?cc=shivam.p36181@gmail.com,rayan.dabbagh@gmail.com&subject=[Triage AI Inquiry] *Please provide details about the purpose of your inquiry*"
									style={{ textDecoration: 'none' }}
								>
									<Box
										sx={{
											width: { xs: '100%', sm: '320px' },
											padding: '28px',
											background: 'rgba(26, 74, 19, 0.05)',
											border: '1px solid rgba(13, 37, 10, 0.65)',
											borderRadius: '22px',
											boxSizing: 'border-box',
											position: 'relative',
											textAlign: 'left',
											color: '#FFF',
										}}
									>
										<Typography
											variant="h5"
											sx={{ fontWeight: 600, mb: 2 }}
										>
											Got questions?
										</Typography>

										<Typography
											variant="subtitle2"
											sx={{ color: '#FFF', fontWeight: 400, opacity: 0.5 }}
										>
											We're here to help! Reach out clicking this box, and our team will get back to
											you ASAP.
										</Typography>

										<img
											src={FooterCardBg}
											alt="Footer Card BG"
											style={{ position: 'absolute', right: 20, top: 20, height: '80%' }}
										/>
									</Box>
								</a>
							</Grid>
						</Grid>

						<Dialog
							onClose={handleLegalsClose}
							open={legalsOpen}
							fullScreen={true}
							scroll="body"
							PaperProps={{
								style: {
									backgroundColor: '#000',
									color: '#FFF',
									justifyContent: 'center',
									padding: '20px',
									borderRadius: '15px',
									boxSizing: 'border-box',
								},
							}}
							slotProps={{ backdrop: { style: { backgroundColor: 'rgba(5, 15, 4, 0.75)' } } }}
						>
							<Box sx={{ width: '100%', display: 'grid' }}>
								<IconButton
									onClick={handleLegalsClose}
									sx={{ justifyContent: 'flex-end' }}
								>
									<X
										color="#FFF"
										style={{ marginBottom: '20px', justifySelf: 'flex-end' }}
									/>
								</IconButton>
							</Box>

							<Box sx={{ maxWidth: '580px', margin: '0 auto' }}>
								{legalsType === 'terms' && <TOS />}
								{legalsType === 'privacy' && <PrivacyPolicy />}
							</Box>
						</Dialog>
					</footer>

					<GradientDivider sx={{ opacity: 0.3, mb: '10px', mt: { xs: '70px' } }} />

					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							pb: '10px',
						}}
					>
						<Typography
							variant="subtitle2"
							sx={{ color: '#FFF', fontWeight: 400, textAlign: 'left' }}
						>
							All rights reserved. Triage.ai ©{new Date().getFullYear()}
						</Typography>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								color: '#FFF',
								gap: '26px',
							}}
						>
							<Button
								variant="text"
								disableRipple
								sx={{
									ml: '-8px',
									textTransform: 'none',
									minWidth: 'unset',
									color: '#FFF',
									'&:hover': {
										background: 'unset',
										textDecoration: 'underline',
										'& .MuiTypography-subtitle2': {
											color: '#FFF',
										},
									},
								}}
								onClick={() => handleLegalsOpen('terms')}
							>
								<Typography
									variant="subtitle2"
									sx={{ color: 'rgba(236, 255, 239, 0.6)', fontWeight: 600 }}
								>
									Terms of Services
								</Typography>
							</Button>

							<Button
								variant="text"
								disableRipple
								sx={{
									ml: '-8px',
									textTransform: 'none',
									minWidth: 'unset',
									color: '#FFF',
									'&:hover': {
										background: 'unset',
										textDecoration: 'underline',
										'& .MuiTypography-subtitle2': {
											color: '#FFF',
										},
									},
								}}
								onClick={() => handleLegalsOpen('privacy')}
							>
								<Typography
									variant="subtitle2"
									sx={{ color: 'rgba(236, 255, 239, 0.6)', fontWeight: 600 }}
								>
									Privacy Policy
								</Typography>
							</Button>
						</Box>
					</Box>
				</Container>
			</div>
		</ThemeProvider>
	);
}

export default App;
