import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { GradientSectionDivider } from '../landing/landing';
import { Gauge, HandCoins, Headset, Vault } from 'lucide-react';

import TriagePlus from '../../assets/logo-triage-plus.svg';
import BgDots from '../../assets/bg-dots.png';
import TriageIcon from '../../assets/triage-app-icon.png';
import { ChipButton, OutlinedChipButton } from '../../App';
import { useNavigate } from 'react-router-dom';

export const CloudHosting = () => {
	const navigate = useNavigate();

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '90px',
				}}
			>
				<Typography
					variant="h1"
					sx={{
						fontSize: { xs: '2.5rem', sm: '4rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						my: '24px',
						maxWidth: '800px',
					}}
				>
					Cloud-Host Your Ticket Management Software
				</Typography>

				<Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: 'rgba(255, 255, 255, 0.6)',
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					<span style={{ color: '#D0FFD6', textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)' }}>
						Host
					</span>{' '}
					your ticket management software{' '}
					<span style={{ color: '#D0FFD6', textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)' }}>
						on any cloud provider
					</span>{' '}
					and{' '}
					<span style={{ color: '#D0FFD6', textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)' }}>
						tailor your environment
					</span>{' '}
					to meet your unique needs
				</Typography>

				<Box sx={{ mt: '70px', pt: '50px' }}>
					<Box sx={{ width: '100%' }}>
						<Grid
							container
							spacing={7}
							sx={{ textAlign: 'center', alignItems: 'center' }}
						>
							{/* Start row 1 */}
							<Grid
								xs={12}
								sm={6}
								item
							>
								<Typography
									variant="h3"
									sx={{
										fontSize: { xs: '2rem', sm: '2.5rem' },
										fontWeight: 600,
										lineHeight: 1.3125,
										color: '#FFF',
										mb: 3,
									}}
								>
									<span style={{ textAlign: 'center', display: 'block' }}>
										Triage<sup>+</sup> <span style={{ opacity: 0.85 }}>is Triage AI at best</span>
									</span>
								</Typography>

								<Typography
									variant="h5"
									sx={{
										fontSize: { xs: '1rem', md: '1.125rem' },
										color: '#FFF',
										opacity: 0.5,
									}}
								>
									A successful solution goes beyond great software; it includes correct
									implementation with reliable and effective on-going maintenance and support. With
									Triage<sup>+</sup> infrastructure, you get Triage AI at its best, leaving you
									free to focus on your customers without the burden of making sure the application
									is stable, maintained, and secure.
								</Typography>
							</Grid>

							<Grid
								xs={12}
								sm={6}
								item
							>
								<Box
									width={'100%'}
									sx={{ textAlign: 'center', mt: { xs: 0, sm: -3 } }}
								>
									<img
										src={TriagePlus}
										alt="Custom Fields Video"
										style={{ width: '100%' }}
									/>

									<ChipButton
										sx={{
											alignItems: 'flex-start',
											'&:hover': {
                                                backgroundColor: '#F1C40F', // Dark yellow color on hover as well
                                            },
										}}
										onClick={() => navigate('/cloud-trial')}
									>
										<Typography
											variant="subtitle1"
											sx={{
												color: '#000',
												fontWeight: 700,
												textTransform: 'none',
												width: 'fit-content',
												mr: 0.5,
											}}
										>
											Try Triage<sup>+</sup> free for 30 days
										</Typography>
									</ChipButton>
								</Box>
							</Grid>
						</Grid>
					</Box>

					<GradientSectionDivider sx={{ mt: 18, mb: 1, opacity: 0.2 }} />

					<Box
						sx={{
							width: '100%',
							py: 7,
							boxSizing: 'border-box',
						}}
					>
						<Box sx={{ mb: 8 }}>
							<Typography
								variant="h4"
								sx={{
									fontWeight: 600,
									color: '#FFF',
									mb: 1,
								}}
							>
								<span
									style={{
										color: '#D0FFD6',
										opacity: 0.85,
										textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
										position: 'relative',
									}}
								>
									<b>Why choose Triage<sup>+</sup> ?</b>
								</span>
							</Typography>
						</Box>
						<Grid
							container
							spacing={{ xs: 7 }}
						>
							<Grid
								xs={12}
								md={3}
								item
							>
								<Vault
									color="#D0FFD6"
									size={24}
									style={{ marginTop: 2 }}
								/>

								<Box>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#E5FFE9',
											mb: 1,
											mt: 2,
										}}
									>
										Rock-solid infrastructure
									</Typography>
								</Box>
							</Grid>

							<Grid
								xs={12}
								md={3}
								item
							>
								<Gauge
									color="#D0FFD6"
									size={24}
									style={{ marginTop: 2 }}
								/>

								<Box>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#E5FFE9',
											mb: 1,
											mt: 2,
										}}
									>
										99.9% guaranteed uptime
									</Typography>
								</Box>
							</Grid>

							<Grid
								xs={12}
								md={3}
								item
							>
								<Headset
									color="#D0FFD6"
									size={24}
									style={{ marginTop: 2 }}
								/>

								<Box>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#E5FFE9',
											mb: 1,
											mt: 2,
										}}
									>
										Reliable support
									</Typography>
								</Box>
							</Grid>

							<Grid
								xs={12}
								md={3}
								item
							>
								<HandCoins
									color="#D0FFD6"
									size={24}
									style={{ marginTop: 2 }}
								/>

								<Box>
									<Typography
										variant="h5"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#E5FFE9',
											mb: 1,
											mt: 2,
										}}
									>
										Affordable pricing
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>

					<Box
						sx={{
							width: '100%',
							mt: 10,
							padding: { xs: 0, md: 10 },
							boxSizing: 'border-box',
							background: `url(${BgDots})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							WebkitMaskImage:
								'linear-gradient(to right, transparent, #050f04 25%, #050f04 80%, transparent)',
							maskImage:
								'linear-gradient(to right, transparent, #050f04 25%, #050f04 80%, transparent)',
						}}
					>
						<img
							src={TriageIcon}
							alt="Triage Icon"
							style={{ maxWidth: '220px', margin: '0 auto' }}
						/>

						<Typography
							variant="h2"
							sx={{
								fontSize: { xs: '2.5rem', sm: '3.5rem', md: '3.75rem' },
								fontWeight: 600,
								lineHeight: 1.3125,
								color: '#FFF',
							}}
						>
							Built for the future. <br />
							Available today.
						</Typography>

						<Box
							sx={{
								display: 'flex',
								flexDirection: { xs: 'column', sm: 'row' },
								alignItems: 'center',
								justifyContent: 'center',
								gap: '20px',
								mt: 5,
								mb: 5,
							}}
						>
							<OutlinedChipButton onClick={() => navigate('/self-hosting')}>
								<Typography
									variant="subtitle1"
									sx={{
										fontWeight: 700,
										textTransform: 'none',
										width: 'fit-content',
										mr: 0.5,
									}}
								>
									Get started
								</Typography>
							</OutlinedChipButton>

							<ChipButton
								sx={{
									alignItems: 'flex-start',
								}}
								onClick={() => navigate('/cloud-trial')}
							>
								<Typography
									variant="subtitle1"
									sx={{
										color: '#000',
										fontWeight: 700,
										textTransform: 'none',
										width: 'fit-content',
										mr: 0.5,
									}}
								>
									Try Triage<sup>+</sup> free for 30 days
								</Typography>
							</ChipButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
