import { Box, Container, Grid, Typography, styled } from '@mui/material';
import { BookOpenText, Workflow } from 'lucide-react';
import { ActionButton } from '../self-hosting/self-hosting';

const ServiceCard = styled(Box)(() => ({
    width: '100%',
    minHeight: '220px',
    margin: '0 auto',
    padding: '1.5rem',
    boxSizing: 'border-box',
    borderRadius: '16px',
    backgroundColor: 'rgba(208, 255, 214, 0.02)',
    border: '1px solid rgba(208, 255, 214, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
}));

export const Services = () => {
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '90px',
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: { xs: '2.5rem', sm: '4rem' },
                        fontWeight: 600,
                        lineHeight: 1.05,
                        color: '#FFF',
                        my: '24px',
                        maxWidth: '800px',
                    }}
                >
                    Professional Services
                </Typography>

                <Typography
                    variant="h5"
                    sx={{
                        fontSize: { xs: '1.125rem', sm: '1.375rem' },
                        color: 'rgba(255, 255, 255, 0.6)',
                        maxWidth: { xs: '80%', md: '65%' },
                        margin: '0 auto',
                        mb: '44px',
                    }}
                >
                    Once your Triage AI system is installed, our team can provide{' '}
                    <span
                        style={{
                            color: '#D0FFD6',
                            textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
                        }}
                    >
                        user and admin training, and the configuration guidance
                    </span>{' '}
                    you need to get your system operational as quickly as possible.{' '}
                </Typography>

                <Box
                    sx={{
                        mt: '20px',
                        pt: '50px',
                    }}
                >
                    <Box
                        sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Grid
                            container
                            spacing={4}
                            maxWidth="md"
                        >
                            <Grid
                                xs={12}
                                sm={6}
                                item
                            >
                                <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' }, '&:hover': { 
							    boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
						        }, }}>
                                    <BookOpenText color="#D0FFD6" />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 600,
                                            lineHeight: 1.3125,
                                            color: '#FFF',
                                            mb: 1,
                                            mt: 2,
                                        }}
                                    >
                                        User and admin training
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#D0FFD6',
                                            mb: 0,
                                            opacity: 0.6,
                                        }}
                                    >
                                        Launching a new customer support platform within your organization can be a
                                        daunting task full of complex situations. The Triage AI team can provide training
                                        for you and your team that matches your workflow and specific use cases.
                                    </Typography>
                                </ServiceCard>
                            </Grid>

                            <Grid
                                xs={12}
                                sm={6}
                                item
                            >
                                <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' }, '&:hover': { 
							    boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
						        }, }}>
                                    <Workflow color="#D0FFD6" />
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: 600,
                                            lineHeight: 1.3125,
                                            color: '#FFF',
                                            mb: 1,
                                            mt: 2,
                                        }}
                                    >
                                        Onboarding
                                    </Typography>

                                    <Typography
                                        variant="subtitle2"
                                        sx={{
                                            color: '#D0FFD6',
                                            mb: 0,
                                            opacity: 0.6,
                                        }}
                                    >
                                        Take advantage of our configuration services to ensure your system is running
                                        smoothly, at peak efficiency, and that you're taking full advantage of
                                        Triage AI's robust feature set. We can even help with your system settings and
                                        various email and server configurations.
                                    </Typography>
                                </ServiceCard>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            border: '1px dashed rgba(208, 255, 214, 0.15)',
                            padding: 4,
                            boxSizing: 'border-box',
                            borderRadius: '16px',
                            textAlign: 'left',
                            mt: 12,
                            mb: 8,
                        }}
                    >
                        <Grid
                            container
                            spacing={4}
                            maxWidth="md"
                        >
                            <Grid
                                xs={12}
                                sm={8}
                                item
                            >
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: 500,
                                        color: '#FFF',
                                        mb: 1,
                                    }}
                                >
                                    <b>Enterprise-ready.</b> Leverage Our Experience
                                </Typography>

                                <Typography
                                    variant="subtitle1"
                                    sx={{
                                        color: '#FFF',
                                        opacity: 0.7,
                                    }}
                                >
                                    4-hour training and onboarding session via Zoom: $999
                                </Typography>
                            </Grid>

                            <Grid
                                xs={12}
                                sm={4}
                                item
                                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                            >
                                <a
                                    href="mailto:support@triage-ai.com?cc=shivam.p36181@gmail.com,rayan.dabbagh@gmail.com&subject=[Triage AI Inquiry] *Please provide details about the purpose of your inquiry*"
                                    style={{ textDecoration: 'none', width: '100%' }}
                                >
                                    <ActionButton
                                        sx={{
                                            alignItems: 'flex-start',
                                            width: { xs: '100%', md: 'unset' },
                                            '&:hover': {
                                                backgroundColor: '#F1C40F', // Dark yellow color on hover as well
                                            },
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                color: '#000',
                                                fontWeight: 700,
                                                textTransform: 'none',
                                                width: 'fit-content',
                                                mr: 0.5,
                                            }}
                                        >
                                            Contact us
                                        </Typography>
                                    </ActionButton>
                                </a>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
};
