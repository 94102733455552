import { Box, Chip, Container, Grid, Tab, Typography, styled } from '@mui/material';
import '../../App.css';

import { TabContext, TabList, TabPanel } from '@mui/lab';

import FeaturePreview from '../../assets/feature-preview.png';
import { Play } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const HoverableFeaturePreview = styled('img')(() => ({
	width: '100%',
	display: 'block',
	borderRadius: '45px',
	position: 'relative',
	'&:hover': {
		background: '#e8ffeb1a',
	},
}));

export const ChipBubble = styled(Chip)(() => ({
	background: 'rgba(26, 74, 19, 0.35)',
	border: '1px solid #1A4A13',
	color: '#e8ffeb',
	paddingLeft: '20px',
	paddingTop: '10px',
	paddingRight: '20px',
	paddingBottom: '8px',
	'& .MuiChip-label': {
		padding: 0,
	},
}));

const CustomTab = styled(Tab)(() => ({
	width: '160px',
	textTransform: 'unset',
	position: 'relative',
	// minHeight: '40px',
	height: '56px',
	overflow: 'visible',
	borderTopLeftRadius: '12px',
	borderTopRightRadius: '12px',
	'&.Mui-selected': {
		color: '#FFF',
	},
}));

export const Features = () => {
	const [tabValue, setTabValue] = useState('1');

	const { state } = useLocation();

	useEffect(() => {
		if (state) {
			const { type } = state;
			setTabValue(type === 'core' ? '1' : '2');
		}
	}, [state]);

	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '90px',
				}}
			>
				{/* <ChipBubble
					label={
						<Typography
							variant="caption"
							sx={{
								fontSize: '0.8125rem',
								fontWeight: 600,
								p: 0,
								display: 'flex',
								alignItems: 'flex-start',
							}}
						>
							Our Features
						</Typography>
					}
				/> */}

				<Typography
					variant="h1"
					sx={{
						fontSize: { xs: '2.5rem', sm: '4rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						my: '24px',
					}}
				>
					Revolutionizing Ticket Management Software
				</Typography>

				<Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: 'rgba(255, 255, 255, 0.6)',
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					Empower your workflow with{' '}
					<span
						style={{
							color: '#D0FFD6',
							opacity: 0.85,
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
							position: 'relative',
						}}
					>
						intelligent automation
					</span>{' '}
					and{' '}
					<span
						style={{
							color: '#D0FFD6',
							opacity: 0.85,
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
							position: 'relative',
						}}
					>
						seamless collaboration
					</span>
				</Typography>

				{/* <ChipButton
					sx={{
						alignItems: 'flex-start',
					}}
					onClick={navigateSignUp}
				>
					<Typography
						variant="subtitle1"
						sx={{
							color: '#000',
							fontWeight: 700,
							textTransform: 'none',
							width: 'fit-content',
							mr: 0.5,
						}}
					>
						Sign Up
					</Typography>
					<ArrowUpRight color="#000" />
				</ChipButton> */}

				{/* <Box
					id={pages[0]}
					sx={{
						width: '100%',
						pt: '120px',
					}}
				>
					<Box
						sx={{ position: 'relative', cursor: 'pointer' }}
						onClick={handleOpen}
					>
						<Box
							sx={{
								width: '100%',
								height: '100%',
								background: 'rgba(204, 254, 212, 0.4)',
								position: 'absolute',
								left: '0',
								top: '0',
								filter: { xs: 'blur(15vh)', md: 'blur(30vh)' },
								zIndex: 0,
							}}
						/>

						<Box
							sx={{
								width: '100px',
								height: '100px',
								background: 'rgba(46, 204, 113, 0.25)',
								position: 'absolute',
								left: '50%',
								top: '50%',
								transform: 'translate(-50%, -50%)',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								borderRadius: '100px',
								zIndex: 2,
							}}
						>
							<Box
								sx={{
									width: '60px',
									height: '60px',
									background: '#1A4A13',
									border: '1px solid #FFF',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									borderRadius: '45px',
								}}
							>
								<Play style={{ fill: '#FFF', stroke: '#FFF', marginLeft: '2px' }} />
							</Box>
						</Box>

						<img
							src={AppPreview}
							alt="Triage AI preview"
							style={{ width: '100%', position: 'relative', zIndex: 1 }}
						/>
					</Box>

					<Dialog
						onClose={handleClose}
						open={open}
						fullScreen={fullScreen}
						PaperProps={{
							style: {
								backgroundColor: '#000',
								justifyContent: 'center',
								padding: '20px',
								borderRadius: '15px',
							},
						}}
						slotProps={{ backdrop: { style: { backgroundColor: 'rgba(5, 15, 4, 0.75)' } } }}
					>
						<IconButton
							onClick={handleClose}
							sx={{ justifyContent: 'flex-end' }}
						>
							<X
								color="#FFF"
								style={{ marginBottom: '20px', alignSelf: 'flex-end' }}
							/>
						</IconButton>
						<video
							controls
							autoPlay
						>
							<source
								src={ProductVideo}
								type="video/mp4"
							/>
							Your browser does not support HTML video.
						</video>
					</Dialog>
				</Box> */}

				<Box sx={{ mt: '10px', pt: '50px', position: 'relative' }}>
					<Box
						sx={{
							width: '100%',
							height: '305px',
							position: 'absolute',
							top: '-200px',
							left: '0',
							right: '0',
							marginX: 'auto',
							display: 'flex',
							alignItems: 'flex-end',
							justifyContent: 'center',
							overflow: 'hidden',
							// background:
							// 	'radial-gradient(ellipse at bottom center, rgba(204, 254, 212, 0.1) 0%, rgba(204, 254, 212, 0) 50%)',
						}}
					>
						<Box
							sx={{
								width: '100%',
								height: '100%',
								background:
									'radial-gradient(ellipse at bottom center, rgba(204, 254, 212, 0.05) 0%, rgba(204, 254, 212, 0) 60%)',
								// filter: { xs: 'blur(2.5vh)', md: 'blur(10vh)' },
							}}
						/>
					</Box>

					<Box sx={{ width: '100%' }}>
						<TabContext value={tabValue}>
							<Box
								sx={{
									width: '100%',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '56px',
									mb: 5,
								}}
							>
								{/* Left div */}
								<Box
									sx={{
										flex: '1 1 25%',
										height: '40px',
										borderBottom: '1px solid' /* Ensure the border is at least 2px high */,
										borderImage: 'linear-gradient(to right, rgba(34, 53, 36, 0), #223524) 1',
										alignSelf: 'flex-end',
										marginRight: '-1px',
									}}
								/>

								<Box
									sx={{
										flex: '1 1 25%',
										height: '40px',
										borderBottom: '1px solid #223524',
										borderBottomRightRadius: tabValue === '1' ? '12px' : 0,
										borderRight: tabValue === '1' ? '1px solid #223524' : 0,
										alignSelf: 'flex-end',
										marginRight: '-1px',
										zIndex: 1,
									}}
								/>

								{/* TabList */}
								<Box
									sx={{
										flex: '1 1 25%',
										display: 'flex',
										alignItems: 'center',
										alignSelf: 'flex-start',
									}}
								>
									<TabList
										onChange={handleChange}
										TabIndicatorProps={{
											style: {
												backgroundColor: 'unset',
											},
										}}
									>
										<CustomTab
											className={
												tabValue === '1' ? 'active-tab core-active' : 'inactive-tab core-inactive'
											}
											sx={{ zIndex: tabValue === '2' ? 1 : 0 }}
											label={
												<Typography
													variant="h6"
													sx={{ fontWeight: 600, position: 'absolute', top: '14px' }}
												>
													Core
												</Typography>
											}
											value="1"
										/>

										<CustomTab
											className={
												tabValue === '2'
													? 'active-tab advanced-active'
													: 'inactive-tab advanced-inactive'
											}
											sx={{ ml: tabValue === '2' ? 0 : '-1px' }}
											label={
												<Typography
													variant="h6"
													sx={{ fontWeight: 600, position: 'absolute', top: '14px' }}
												>
													Advanced
												</Typography>
											}
											value="2"
										/>
									</TabList>
								</Box>

								{/* Right div */}
								<Box
									sx={{
										flex: '1 1 25%',
										height: '40px',
										borderBottom: '1px solid #223524',
										borderBottomLeftRadius: tabValue === '2' ? '12px' : 0,
										borderLeft: tabValue === '2' ? '1px solid #223524' : 0,
										alignSelf: 'flex-end',
										marginLeft: '-1px',
										zIndex: 1,
									}}
								/>

								<Box
									sx={{
										flex: '1 1 25%',
										height: '40px',
										borderBottom: '1px solid' /* Ensure the border is at least 2px high */,
										borderImage: 'linear-gradient(to left, rgba(34, 53, 36, 0), #223524) 1',
										alignSelf: 'flex-end',
										marginRight: '-1px',
									}}
								/>
							</Box>

							<TabPanel value="1">
								<Grid
									container
									spacing={7}
									sx={{ textAlign: 'left', alignItems: 'center' }}
								>
									{/* Start row 1 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Custom Fields
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Customize the information collected from users when they submit a ticket, so
											you can tackle their issue faster.
											<br />
											<br />
											You have the option to personalize the information you collect for each ticket
											or for specific help topics that clients can select when creating a ticket.
											These customization features like Custom Fields, Forms, and Lists can be
											included with every web ticket or appear only when a particular help topic is
											chosen.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										{/* <Box sx={{ position: 'relative' }}>
								<HoverableFeaturePreview
									src={FeaturePreview}
									alt="Custom Fields Video"
								/>

								<Box
									sx={{
										position: 'absolute',
										top: 0,
										left: 0,
										width: '100%',
										height: '100%',
										background: 'rgba(0, 0, 0, 0.6)',
										borderRadius: '45px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Box
										sx={{
											width: '100px',
											height: '100px',
											background: 'rgba(208, 255, 214, 0.4)',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											borderRadius: '50%',
										}}
									>
										<Box
											sx={{
												width: '60px',
												height: '60px',
												background: '#1A4A13',
												border: '1px solid #D0FFD6',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												borderRadius: '50%',
											}}
										>
											<Play style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }} />
										</Box>
									</Box>
								</Box>
							</Box> */}
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 1 */}

									{/* Start row 2 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Tailored Ticket Organization
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Customize your ticket views with custom queues, displaying tickets based on
											criteria you define.
											<br />
											<br />
											Craft personalized ticket views, specifying the information you want to
											prioritize. Custom columns enhance your ticket list by adding additional
											fields not initially displayed.
										</Typography>
									</Grid>
									{/* End row 2 */}

									{/* Start row 3 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Automated Ticket Classification
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											{' '}
											Quickly identify areas with a surge in ticket volume.
											<br />
											<br />
											Triage AI utilizes cutting-edge technologies to analyze incoming tickets and
											classify them into predefined categories. You'll provide information about
											your company's operations and common ticket types, and we'll create a
											customized classifier tailored to your needs. Additionally, you can fine-tune
											your model based on past resolved tickets with just one click on Triage AI.{' '}
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 3 */}

									{/* Start row 4 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Intelligent Ticket Routing
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Define rules to direct incoming tickets to the appropriate departments or
											agents, and trigger predefined actions.
											<br />
											<br />
											Define rules to direct incoming tickets to the appropriate departments or
											agents and trigger predefined actions. Our routing mechanisms ensure tickets
											are directed to the appropriate subject matter experts through any preferred
											communication channel.
										</Typography>
									</Grid>
									{/* End row 4 */}

									{/* Start row 5 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Agent Collision Avoidance
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Ticket locking mechanism to prevent conflicting or duplicate responses from
											multiple agents..
											<br />
											<br />
											Ensure that only one agent handles a ticket at a time by setting a lock
											duration. During this period, other staff members cannot respond to the ticket
											until the lock expires, avoiding multiple responses to the same ticket.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 5 */}

									{/* Start row 6 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Assign, Transfer, & Referral
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Effortlessly manage ticket workflow by transferring tickets between
											departments, assigning them to specific agents or teams, and referring tickets
											while maintaining visibility.
											<br />
											<br />
											Ensure tickets are handled by the right agents by auto-assigning them based on
											help topics or departments. If reassignment is needed, you can easily reassign
											tickets to different agents, teams, or departments. Transfer, assignment, and
											referral actions are logged in the ticket thread to maintain a clear record of
											the ticket's journey.
										</Typography>
									</Grid>
									{/* End row 6 */}

									{/* Start row 7 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Auto-Responder
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Set up configurable automatic replies to be sent when a new ticket is opened
											or a message is received.s
											<br />
											<br />
											Personalize auto responses by pulling information directly from the ticket,
											such as the user's first name. Customize these automated replies for each
											department and associate them with specific help topics to ensure relevant and
											accurate communication.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 7 */}

									{/* Start row 8 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Thread Action
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Enable agents to create a new ticket or task directly from a ticket's or
											task's thread entry.
											<br />
											<br />
											Easily manage complex requests by creating separate tickets or tasks from user
											messages within a thread. This feature allows agents to generate new items
											while maintaining the original context. Thread actions are logged in the
											original ticket thread, providing references to both the new item and the
											original message it was derived from.
										</Typography>
									</Grid>
									{/* End row 8 */}

									{/* Start row 9 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Service Level Agreements
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Effortlessly track tickets and due dates with SLA Plans.
											<br />
											<br />
											Receive overdue alerts and notices for missed due dates, along with priority
											escalation. Create unlimited SLA Plans and assign them to specific help
											topics, departments, or ticket filters to ensure timely and efficient ticket
											management.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 9 */}

									{/* Start row 10 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Customer Portal
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Archive all support requests and responses online for easy user access. <br />
											<br />
											Users can log in with their email and ticket number or register a profile for
											full access to all their associated tickets.
										</Typography>
									</Grid>
									{/* End row 10 */}

									{/* Start row 11 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Advanced Search
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Refine your search parameters using Advanced Search capabilities.
											<br />
											<br />
											Save your preferred criteria for quick access to future searches, including
											custom fields. Search results are organized into separate queues and can be
											exported to a CSV file. Agents have the flexibility to choose which fields to
											include in the export.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 11 */}

									{/* Start row 12 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Task Management
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Empower agents with an internal to-do list.
											<br />
											<br />
											Tasks can be linked to tickets or exist independently within the help desk.
											Ticket-related tasks ensure tickets remain open until all associated tasks are
											completed. Collaborate with external users by adding them to task items,
											keeping them distinct from ticket threads and contents.
										</Typography>
									</Grid>
									{/* End row 12 */}
								</Grid>
							</TabPanel>

							<TabPanel value="2">
								<Grid
									container
									spacing={7}
									sx={{ textAlign: 'left', alignItems: 'center' }}
								>
									{/* Start row 1 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Advanced Analytics and Reporting
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Gain deeper insights into ticket trends and performance metrics with advanced
											analytics and reporting tools.
											<br />
											<br />
											Our system provides dynamic data visualization in any format you need. Simply
											ask, "I want to visualize my outstanding tickets per category in a pie chart",
											and the system will generate the chart for you. This feature is designed to be
											intuitive and highly customizable, offering you a powerful way to analyze your
											stored ticket data effortlessly.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* End row 1 */}

									{/* Start row 2 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Sophisticated Testing Frameworks
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Unlock the full potential of your ticket classifiers with our sophisticated
											testing frameworks.
											<br />
											<br />
											Our advanced testing tools include macro-testing, which allows you to upload
											testing files and receive comprehensive ML metrics, demonstrating the power and
											effectiveness of your model. Additionally, all results can be translated into
											English, eliminating the need for technical expertise to understand the
											insights. For quicker assessments, our micro-testing feature lets you classify
											fictional tickets on demand, one by one, providing immediate feedback.
										</Typography>
									</Grid>
									{/* End row 2 */}
									{/* Start row 1 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Advanced Ticket Recognition + Autoresponder
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Optimize your ticket resolution process with our advanced ticket recognition feature and autoresponder
											<br />
											<br />
											Our system intelligently remembers previous instances of similar tickets and automatically responds to them using solutions provided for similar cases. This
											ensures that recurring issues are handled promptly significantly reducing resolution times.
										</Typography>
									</Grid>

									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									{/* Start row 2 */}
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Easy Integration to Sprint Boards
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Streamline your workflow with our seamless integration to popular sprint boards like Trello and more.
											<br />
											<br />
											Our efficient integration tools enable you to add any ticket to your desired project management tool in seconds. 
											Whether you're working on a new project or managing ongoing tasks, our integration ensures that your tickets are accurately and swiftly added to your sprint boards, 
											allowing you to focus on what matters most—completing your tasks on time.
										</Typography>
									</Grid>
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Easy Integration to Online Meeting Platforms
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Enhance your customer support with our effortless integration to online meeting platforms, enabling seamless ticket and call management.
											<br />
											<br /> 
											Whether you need to escalate a ticket to a call or set up a quick meeting, our integration ensures that you can connect with your customers swiftly and efficiently. 
											Compatible with popular online meeting platforms such as Zoom, Microsoft Teams, Google Meet, and more, this feature not only saves time but also enhances your ability to provide prompt and personalized support. 
										</Typography>
									</Grid>
									
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Box sx={{ position: 'relative' }}>
											<HoverableFeaturePreview
												src={FeaturePreview}
												alt="Custom Fields Video"
											/>

											<Box
												sx={{
													position: 'absolute',
													top: 0,
													left: 0,
													width: '100%',
													height: '100%',
													background: 'rgba(0, 0, 0, 0.6)',
													borderRadius: '45px',
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Box
													sx={{
														width: '100px',
														height: '100px',
														background: 'rgba(208, 255, 214, 0.4)',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														borderRadius: '50%',
													}}
												>
													<Box
														sx={{
															width: '60px',
															height: '60px',
															background: '#1A4A13',
															border: '1px solid #D0FFD6',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															borderRadius: '50%',
														}}
													>
														<Play
															style={{ fill: '#D0FFD6', stroke: '#D0FFD6', marginLeft: '2px' }}
														/>
													</Box>
												</Box>
											</Box>
										</Box>
									</Grid>
									<Grid
										xs={12}
										sm={6}
										item
									>
										<Typography
											variant="h3"
											sx={{
												fontSize: { xs: '2rem', sm: '2.5rem' },
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
											}}
										>
											Automated creation of tickets from Discord channels
										</Typography>

										<Typography
											variant="h5"
											sx={{
												fontSize: { xs: '1rem', md: '1.125rem' },
												color: '#FFF',
												opacity: 0.5,
											}}
										>
											Automatically generate tickets from Discord channels directly into your Triage support platform.
											<br />
											<br />
											Automatically convert customer discussions on Discord into tickets on your Triage support platform. 
											Effortlessly integrate these conversations into your project management workflow, ensuring prompt and accurate ticket creation. 
										</Typography>
									</Grid>
								</Grid>
							</TabPanel>
						</TabContext>
					</Box>
				</Box>
			</Box>
		</Container>
	);
};
