import {
	Box,
	Button,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
	tableCellClasses,
} from '@mui/material';
import { Check, ChevronRight, Mail, Phone, Users, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { OutlinedChipButton } from '../../App';
import { GradientSectionDivider } from '../landing/landing';

import BgDots from '../../assets/bg-dots.png';

const ActionButton = styled(Button)(() => ({
	background: '#D0FFD6',
	paddingLeft: '20px',
	paddingRight: '20px',
	borderRadius: '12px',
	paddingTop: 8,
	'&:hover': {
		background: '#e8ffeb',
	},
	'& .MuiTouchRipple-root span': {
		backgroundColor: '#68806b',
	},
}));

const OutlinedActionButton = styled(ActionButton)(() => ({
	border: '1px solid #D0FFD6',
	background: 'unset',
	'&:hover': {
		background: 'rgba(26, 74, 19, 0.35)',
	},
	'& .MuiTouchRipple-root span': {
		backgroundColor: '#68806b',
	},
}));

const StyledListItem = styled('li')(() => ({
	marginBottom: '1rem',
	'&:last-child': {
		marginBottom: 0,
	},
}));

const StyledListSubItem = styled('li')(() => ({
	color: '#FFF',
	'&:marker': {
		color: '#FFF',
	},
}));

const featureList = [
	['Open Source Software', true, true],
	['Core Features', true, true],
	['Extensions & Add-Ons Marketplace', false, true],
	['Subscription-based Support Plans', false, true],
	['Rock-solid Cloud Infrastructure, Guaranteed Uptime', false, true],
	['Customization to fit your business needs', false, true],
];

export const TableDetail = () => {
	const navigate = useNavigate();

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '90px',
				}}
			>
				<Typography
					variant="h1"
					sx={{
						fontSize: { xs: '2.5rem', sm: '4rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						my: '24px',
						maxWidth: '800px',
					}}
				>
					Open Source vs Triage<sup>+</sup>
				</Typography>
				{/* <Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: 'rgba(255, 255, 255, 0.6)',
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					<span
						style={{
							color: '#D0FFD6',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
						}}
					>
						Spin up
					</span>{' '}
					your ticket management software{' '}
					<span
						style={{
							color: '#D0FFD6',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
						}}
					>
						locally
					</span>{' '}
					and{' '}
					<span
						style={{
							color: '#D0FFD6',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
						}}
					>
						tailor your environment
					</span>{' '}
					to meet your unique needs
				</Typography> */}

				<Box
					sx={{
						width: { xs: '100%', md: '85%' },
						mx: { xs: 'auto' },
						mt: { xs: '40px', md: '70px' },
						pt: '50px',
						mb: 8,
					}}
				>
					<Grid
						container
						spacing={{ xs: 7, md: 0 }}
						// maxWidth="lg"
					>
						<Grid
							xs={12}
							md={6}
							item
							sx={{ textAlign: 'left' }}
						>
							<Box
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									padding: '24px',
									borderRadius: '6px',
								}}
							>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{
											color: '#D0FFD6',
											fontWeight: 600,
										}}
									>
										Open source
									</Typography>

									<Box sx={{ opacity: 0.7 }}>
										<Typography
											variant="h6"
											sx={{
												fontWeight: 600,
												lineHeight: 1.3125,
												color: '#FFF',
												mb: 3,
												mt: 0.5,
											}}
										>
											$0
										</Typography>

										<Box
											sx={{
												pb: 3,
												mb: 3,
												mr: '-24px',
												pr: '24px',
												borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
											}}
										>
											<Typography
												variant="subtitle2"
												sx={{
													color: '#FFF',
												}}
											>
												Access to the core features of Triage AI
											</Typography>
										</Box>

										<ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: 0 }}>
											<StyledListItem>
												<Typography
													variant="subtitle2"
													sx={{
														color: '#FFF',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Check
														style={{
															flexShrink: 0,
															marginRight: 10,
															marginBottom: '4px',
															opacity: 0.7,
														}}
														size={'0.875rem'}
													/>
													Core ticketing system
												</Typography>
											</StyledListItem>

											<StyledListItem>
												<Typography
													variant="subtitle2"
													sx={{
														color: '#FFF',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Check
														style={{
															flexShrink: 0,
															marginRight: 10,
															marginBottom: '4px',
															opacity: 0.7,
														}}
														size={'0.875rem'}
													/>
													Basic reporting tools
												</Typography>
											</StyledListItem>

											<StyledListItem>
												<Typography
													variant="subtitle2"
													sx={{
														color: '#FFF',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Check
														style={{
															flexShrink: 0,
															marginRight: 10,
															marginBottom: '4px',
															opacity: 0.7,
														}}
														size={'0.875rem'}
													/>
													Email piping and auto-responses
												</Typography>
											</StyledListItem>

											<StyledListItem>
												<Typography
													variant="subtitle2"
													sx={{
														color: '#FFF',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Check
														style={{
															flexShrink: 0,
															marginRight: 10,
															marginBottom: '4px',
															opacity: 0.7,
														}}
														size={'0.875rem'}
													/>
													Community support
												</Typography>
											</StyledListItem>

											<StyledListItem>
												<Typography
													variant="subtitle2"
													sx={{
														color: '#FFF',
														display: 'flex',
														alignItems: 'center',
													}}
												>
													<Check
														style={{
															flexShrink: 0,
															marginRight: 10,
															marginBottom: '4px',
															opacity: 0.7,
														}}
														size={'0.875rem'}
													/>
													Continuous access to latest updates
												</Typography>
											</StyledListItem>
										</ul>
									</Box>
								</Box>

								<OutlinedActionButton
									sx={{
										alignItems: 'flex-start',
										width: 'fit-content',
									}}
									onClick={() =>
										window.open(
											'https://github.com/rayandabbagh/triage.ai.backend/releases',
											'_blank'
										)
									}
								>
									<Typography
										variant="subtitle2"
										sx={{
											color: '#D0FFD6',
											fontWeight: 700,
											textTransform: 'none',
											width: 'fit-content',
											mt: 0.15,
										}}
									>
										Get started
									</Typography>
								</OutlinedActionButton>
							</Box>
						</Grid>

						<Grid
							xs={12}
							md={6}
							item
							sx={{ textAlign: 'left' }}
						>
							<Box
								sx={{
									height: '100%',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
									padding: '24px',
									borderRadius: '8px',
									background:
										'linear-gradient(169deg, rgba(46, 204, 113, 0.3), #000 155.91%), rgba(26, 74, 19, 0.7)',
									boxShadow:
										'0 167px 67px 0 rgba(0,0,0,.02),0 94px 56px 0 rgba(0,0,0,.08),0 42px 42px 0 rgba(0,0,0,.13),0 10px 23px 0 rgba(0,0,0,.15)',
								}}
							>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{
											color: '#D0FFD6',
											fontWeight: 600,
										}}
									>
										Triage<sup>+</sup> (Cloud-hosted)
									</Typography>

									<Typography
										variant="h6"
										sx={{
											fontWeight: 600,
											lineHeight: 1.3125,
											color: '#FFF',
											mb: 3,
											mt: 0.5,
										}}
									>
										Starting at $49.99/month
									</Typography>

									<Box
										sx={{
											pb: 3,
											mb: 3,
											mx: '-24px',
											px: '24px',
											borderBottom: '1px solid rgba(255, 255, 255, 0.15)',
										}}
									>
										<Typography
											variant="subtitle2"
											sx={{
												color: '#FFF',
											}}
										>
											Fully managed, cloud-hosted version of Triage AI.
										</Typography>
									</Box>

									<Typography
										variant="subtitle2"
										sx={{
											color: '#FFF',
										}}
									>
										Perfect for organizations that prefer a hassle-free setup with robust
										infrastructure and guaranteed uptime
									</Typography>

									<ul style={{ listStyle: 'none', paddingLeft: 0, marginBottom: 0 }}>
										<StyledListItem>
											<Typography
												variant="subtitle2"
												sx={{
													color: '#FFF',
													display: 'flex',
													alignItems: 'center',
													fontWeight: 600,
												}}
											>
												<Check
													style={{
														flexShrink: 0,
														marginRight: 10,
														marginBottom: '4px',
													}}
													size={'0.875rem'}
												/>
												All open source features and...
											</Typography>
										</StyledListItem>

										<StyledListItem>
											<Typography
												variant="subtitle2"
												sx={{
													color: '#FFF',
													display: 'flex',
													alignItems: 'center',
													fontWeight: 600,
												}}
											>
												<Check
													style={{
														flexShrink: 0,
														marginRight: 10,
														marginBottom: '4px',
													}}
													size={'0.875rem'}
												/>
												Extensions and Add-Ons Marketplace
											</Typography>

											<ul>
												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.9,
														}}
													>
														$100 per advanced feature addition for the first month
													</Typography>
												</StyledListSubItem>

												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.9,
														}}
													>
														$50 per month per feature for continuous support and upgrades
													</Typography>
												</StyledListSubItem>

												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.8,
														}}
													>
														Offers a variety of extensions and add-ons to enhance the functionality
														of Triage AI
													</Typography>
												</StyledListSubItem>
											</ul>
										</StyledListItem>

										<StyledListItem>
											<Typography
												variant="subtitle2"
												sx={{
													color: '#FFF',
													display: 'flex',
													alignItems: 'center',
													fontWeight: 600,
												}}
											>
												<Check
													style={{
														flexShrink: 0,
														marginRight: 10,
														marginBottom: '4px',
													}}
													size={'0.875rem'}
												/>
												Subscription-based Support Plans
											</Typography>

											<ul>
												<StyledListSubItem sx={{ mb: 1 }}>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.8,
														}}
													>
														Premium Support
													</Typography>

													<ul>
														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.9,
																}}
															>
																$10,000 per year from 9am to 5pm
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Dedicated support channels
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Guaranteed response times
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Software updates
															</Typography>
														</StyledListSubItem>
													</ul>
												</StyledListSubItem>

												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.8,
														}}
													>
														Enterprise Support
													</Typography>

													<ul>
														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.9,
																}}
															>
																$15,000 per year 24/7
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Priority support
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Dedicated account management
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Advanced configuration assistance
															</Typography>
														</StyledListSubItem>

														<StyledListSubItem>
															<Typography
																variant="subtitle2"
																sx={{
																	display: 'flex',
																	alignItems: 'center',
																	fontWeight: 600,
																	opacity: 0.8,
																}}
															>
																Continuous monitoring
															</Typography>
														</StyledListSubItem>
													</ul>
												</StyledListSubItem>
											</ul>
										</StyledListItem>

										<StyledListItem>
											<Typography
												variant="subtitle2"
												sx={{
													color: '#FFF',
													display: 'flex',
													alignItems: 'center',
													fontWeight: 600,
												}}
											>
												<Check
													style={{
														flexShrink: 0,
														marginRight: 10,
														marginBottom: '4px',
													}}
													size={'0.875rem'}
												/>
												Infrastructure Setup (AWS, Azure, GCP)
											</Typography>

											<ul>
												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.9,
														}}
													>
														$250 for the first month ($100 per month thereafter)
													</Typography>
												</StyledListSubItem>

												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.8,
														}}
													>
														Set up up Triage AI on preferred cloud platforms
													</Typography>
												</StyledListSubItem>
											</ul>
										</StyledListItem>

										<StyledListItem>
											<Typography
												variant="subtitle2"
												sx={{
													color: '#FFF',
													display: 'flex',
													alignItems: 'center',
													fontWeight: 600,
												}}
											>
												<Check
													style={{
														flexShrink: 0,
														marginRight: 10,
														marginBottom: '4px',
													}}
													size={'0.875rem'}
												/>
												Customization Services
											</Typography>

											<ul>
												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.9,
														}}
													>
														Pricing varies based on the scope of the task
													</Typography>
												</StyledListSubItem>

												<StyledListSubItem>
													<Typography
														variant="subtitle2"
														sx={{
															display: 'flex',
															alignItems: 'center',
															fontWeight: 600,
															opacity: 0.8,
														}}
													>
														Tailored customization services to fit specific business needs
													</Typography>
												</StyledListSubItem>
											</ul>
										</StyledListItem>
									</ul>
								</Box>

								<ActionButton
									sx={{
										alignItems: 'flex-start',
										width: 'fit-content',
										// px: 1,
									}}
									onClick={() => navigate('/cloud-trial')}
								>
									<Typography
										variant="subtitle2"
										sx={{
											color: '#000',
											fontWeight: 700,
											textTransform: 'none',
											width: 'fit-content',
											mt: 0.15,
										}}
									>
										Get started
									</Typography>
								</ActionButton>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Container>
	);
};
