import {
	Box,
	Button,
	Container,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	styled,
	tableCellClasses,
} from '@mui/material';
import { Check, ChevronRight, Mail, Phone, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { OutlinedChipButton } from '../../App';
import { GradientSectionDivider } from '../landing/landing';

import BgDots from '../../assets/bg-dots.png';

export const ActionButton = styled(Button)(() => ({
	background: '#D0FFD6',
	paddingLeft: '20px',
	paddingRight: '20px',
	borderRadius: '12px',
	paddingTop: 8,
	'&:hover': {
		background: '#e8ffeb',
	},
	'& .MuiTouchRipple-root span': {
		backgroundColor: '#68806b',
	},
}));

export const OutlinedActionButton = styled(ActionButton)(() => ({
	border: '1px solid #D0FFD6',
	background: 'unset',
	'&:hover': {
		background: 'rgba(26, 74, 19, 0.35)',
	},
	'& .MuiTouchRipple-root span': {
		backgroundColor: '#68806b',
	},
}));

export const OutlineBox = styled(Box)(() => ({
	borderBottom: '1px dashed rgba(208, 255, 214, 0.15)',
}));

const featureList = [
	['Open Source Software', true, true],
	['Core Features', true, true],
	['Extensions & Add-Ons Marketplace', false, true],
	['Subscription-based Support Plans', false, true],
	['Rock-solid Cloud Infrastructure, Guaranteed Uptime', false, true],
	['Customization to fit your business needs', false, true],
];

export const SelfHosting = () => {
	const navigate = useNavigate();

	return (
		<Container maxWidth="lg">
			<Box
				sx={{
					textAlign: 'center',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '90px',
				}}
			>
				<Typography
					variant="h1"
					sx={{
						fontSize: { xs: '2.5rem', sm: '4rem' },
						fontWeight: 600,
						lineHeight: 1.05,
						color: '#FFF',
						my: '24px',
						maxWidth: '800px',
					}}
				>
					Self-Host Your Ticket Management Software
				</Typography>

				<Typography
					variant="h5"
					sx={{
						fontSize: { xs: '1.125rem', sm: '1.375rem' },
						color: 'rgba(255, 255, 255, 0.6)',
						maxWidth: { xs: '80%', md: '65%' },
						margin: '0 auto',
						mb: '44px',
					}}
				>
					<span
						style={{
							color: '#D0FFD6',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
						}}
					>
						Spin up
					</span>{' '}
					your ticket management software{' '}
					<span
						style={{
							color: '#D0FFD6',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
						}}
					>
						locally
					</span>{' '}
					and{' '}
					<span
						style={{
							color: '#D0FFD6',
							textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)',
						}}
					>
						tailor your environment
					</span>{' '}
					to meet your unique needs
				</Typography>

				<Box sx={{ width: '100%', mt: { xs: '40px', md: '70px' }, pt: '50px' }}>
					<TableContainer>
						<Table
							sx={{
								[`& .${tableCellClasses.root}`]: {
									borderBottom: 'none',
									padding: '10px',
								},
							}}
							aria-label="simple table"
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<OutlineBox
											sx={{
												borderBottom: { xs: 'none', md: '1px dashed rgba(208, 255, 214, 0.15)' },
											}}
										>
											<Typography
												variant="h4"
												sx={{
													display: { xs: 'none', sm: 'block' },
													fontWeight: 600,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: 3,
												}}
											>
												Tier
												<br />
												Comparison
											</Typography>

											<Typography
												variant="h5"
												sx={{
													display: { xs: 'block', sm: 'none' },
													fontWeight: 600,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: { xs: 0, md: 2 },
												}}
											>
												Tier
												<br />
												comparison
											</Typography>
										</OutlineBox>
									</TableCell>

									<TableCell
										align="center"
										sx={{ width: { md: '21%', lg: 'unset' } }}
									>
										<OutlineBox
											sx={{
												borderBottom: { xs: 'none', md: '1px dashed rgba(208, 255, 214, 0.15)' },
											}}
										>
											<Typography
												variant="h5"
												sx={{
													display: { xs: 'none', md: 'block' },
													fontWeight: 600,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: { xs: 0, md: 2 },
												}}
											>
												Open Source
											</Typography>

											<Typography
												variant="subtitle1"
												sx={{
													display: { xs: 'block', md: 'none' },
													fontWeight: 700,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: { xs: 0, md: 2 },
												}}
											>
												Open Source
											</Typography>

											<OutlinedActionButton
												sx={{
													alignItems: 'flex-start',
													marginBottom: '22px',
													display: { xs: 'none', md: 'inline-flex' },
												}}
												onClick={() =>
													window.open(
														'https://github.com/rayandabbagh/triage.ai.backend/releases',
														'_blank'
													)
												}
											>
												<Typography
													variant="subtitle1"
													sx={{
														color: '#D0FFD6',
														fontWeight: 700,
														textTransform: 'none',
														width: 'fit-content',
														mr: 0.5,
													}}
												>
													Download
												</Typography>
											</OutlinedActionButton>
										</OutlineBox>
									</TableCell>

									<TableCell
										align="center"
										sx={{ width: { md: '30%', lg: 'unset' } }}
									>
										<OutlineBox
											sx={{
												borderBottom: { xs: 'none', md: '1px dashed rgba(208, 255, 214, 0.15)' },
											}}
										>
											<Typography
												variant="h5"
												sx={{
													display: { xs: 'none', md: 'block' },
													fontWeight: 600,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: { xs: 0, md: 2 },
												}}
											>
												Cloud-hosted
											</Typography>

											<Typography
												variant="subtitle1"
												sx={{
													display: { xs: 'block', md: 'none' },
													fontWeight: 700,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: { xs: 0, md: 2 },
												}}
											>
												Cloud-hosted
											</Typography>

											<ActionButton
												sx={{
													alignItems: 'flex-start',
													marginBottom: '24px',
													display: { xs: 'none', md: 'inline-flex' },
												}}
												onClick={() => navigate('/cloud-trial')}
											>
												<Typography
													variant="subtitle1"
													sx={{
														color: '#000',
														fontWeight: 700,
														textTransform: 'none',
														width: 'fit-content',
														mr: 0.5,
													}}
												>
													Try it free for 30 days
												</Typography>
											</ActionButton>
										</OutlineBox>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								<TableRow sx={{ border: 0, opacity: { xs: 0.7, md: 1 } }}>
									<TableCell></TableCell>
									<TableCell align="center">
										<Typography
											variant="subtitle1"
											sx={{
												display: { xs: 'none', md: 'block' },
												color: '#FFF',
												fontWeight: 700,
											}}
										>
											Free
										</Typography>

										<Typography
											variant="subtitle2"
											sx={{
												display: { xs: 'block', md: 'none' },
												color: '#FFF',
												fontWeight: 700,
											}}
										>
											Free
										</Typography>
									</TableCell>

									<TableCell align="center">
										<Typography
											variant="subtitle1"
											sx={{
												display: { xs: 'none', md: 'block' },
												color: '#FFF',
												fontWeight: 700,
											}}
										>
											Starting at $49.99/mo
										</Typography>

										<Typography
											variant="subtitle2"
											sx={{
												display: { xs: 'block', md: 'none' },
												color: '#FFF',
												fontWeight: 700,
											}}
										>
											Starting at $49.99/mo
										</Typography>
									</TableCell>
								</TableRow>

								<TableRow sx={{ border: 0, opacity: { xs: 0.6, md: 1 } }}>
									<TableCell></TableCell>
									<TableCell align="center">
										<Typography
											variant="subtitle1"
											sx={{
												display: { xs: 'none', md: 'block' },
												color: '#FFF',
												mb: '8px',
											}}
										>
											Community Support
										</Typography>

										<Typography
											variant="subtitle2"
											sx={{
												display: { xs: 'block', md: 'none' },
												color: '#FFF',
											}}
										>
											Community Support
										</Typography>
									</TableCell>

									<TableCell align="center">
										<Typography
											variant="subtitle1"
											sx={{
												display: { xs: 'none', md: 'block' },
												color: '#FFF',
											}}
										>
											Powered by Triage<sup>+</sup>
										</Typography>

										<Typography
											variant="subtitle2"
											sx={{
												display: { xs: 'block', md: 'none' },
												color: '#FFF',
											}}
										>
											Powered by Triage<sup>+</sup>
										</Typography>
									</TableCell>
								</TableRow>

								{featureList.map(feature => (
									<TableRow
										key={feature[0]}
										sx={{ borderTop: '1px solid rgba(208, 255, 214, 0.1)' }}
									>
										<TableCell>
											<Typography
												variant="subtitle1"
												sx={{
													display: { xs: 'none', md: 'block' },
													paddingY: '7px',
													color: '#FFF',
												}}
											>
												{feature[0]}
											</Typography>

											<Typography
												variant="subtitle2"
												sx={{
													display: { xs: 'block', md: 'none' },
													color: '#FFF',
												}}
											>
												{feature[0]}
											</Typography>
										</TableCell>

										<TableCell align="center">
											<Typography
												variant="subtitle1"
												sx={{
													color: '#FFF',
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												{feature[1] ? <Check color="#2ECC71" /> : <X />}
											</Typography>
										</TableCell>

										<TableCell align="center">
											<Typography
												variant="subtitle1"
												sx={{
													color: '#FFF',
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												{feature[2] ? <Check color="#2ECC71" /> : <X />}
											</Typography>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<OutlinedChipButton
						sx={{
							alignItems: 'flex-start',
							mt: 5,
							pr: 1.4,
						}}
						onClick={() => navigate('/table-detail')}
					>
						<Typography
							variant="subtitle1"
							sx={{
								fontWeight: 700,
								textTransform: 'none',
								width: 'fit-content',
								mr: 0.5,
							}}
						>
							More Details
						</Typography>
						<ChevronRight color="#FFF" />
					</OutlinedChipButton>

					<GradientSectionDivider sx={{ mt: 18, mb: 1, opacity: 0.2 }} />

					<Box
						sx={{
							width: '100%',
							py: 7,
							boxSizing: 'border-box',
							background: `url(${BgDots})`,
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Box sx={{ mb: 8 }}>
							<Typography
								variant="h4"
								sx={{
									fontWeight: 600,
									color: '#FFF',
									mb: 1,
								}}
							>
								Having trouble choosing the right edition?
							</Typography>

							<Typography
								variant="h6"
								sx={{
									fontWeight: 600,
									lineHeight: 1.3125,
									color: '#FFF',
									opacity: 0.5,
								}}
							>
								Contact us today and we can help you
							</Typography>
						</Box>

						<Grid
							container
							spacing={{ xs: 7 }}
							maxWidth="md"
						>
							<Grid
								xs={12}
								md={6}
								item
							>
								<a
									href="tel:+1 404-936-8747"
									style={{ textDecoration: 'none' }}
								>
									<Button
										sx={{
											display: 'flex',
											flexDirection: 'column',
											textTransform: 'unset',
											margin: '0 auto',
											py: '12px',
											px: '16px',
											borderRadius: '12px',
										}}
									>
										<Phone
											color="#D0FFD6"
											size={24}
											style={{ marginTop: 2 }}
										/>

										<Box>
											<Typography
												variant="h5"
												sx={{
													fontWeight: 600,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: 1,
													mt: 2,
												}}
											>
												+1 (404) 936-8747
											</Typography>

											<Typography
												variant="subtitle2"
												sx={{
													color: '#D0FFD6',
													// opacity: 0.6,
													width: '70%',
													margin: '0 auto',
												}}
											>
												Phone
											</Typography>
										</Box>
									</Button>
								</a>
							</Grid>

							<Grid
								xs={12}
								md={6}
								item
							>
								<a
									href="mailto:support@triage-ai.com?cc=shivam.p36181@gmail.com,rayan.dabbagh@gmail.com&subject=[Triage AI Inquiry] *Please provide details about the purpose of your inquiry*"
									style={{ textDecoration: 'none' }}
								>
									<Button
										sx={{
											display: 'flex',
											flexDirection: 'column',
											textTransform: 'unset',
											margin: '0 auto',
											py: '12px',
											px: '16px',
											borderRadius: '12px',
										}}
									>
										<Mail
											color="#D0FFD6"
											size={24}
											style={{ marginTop: 2 }}
										/>

										<Box>
											<Typography
												variant="h5"
												sx={{
													fontWeight: 600,
													lineHeight: 1.3125,
													color: '#FFF',
													mb: 1,
													mt: 2,
												}}
											>
												support@triage-ai.com
											</Typography>

											<Typography
												variant="subtitle2"
												sx={{
													color: '#D0FFD6',
													// opacity: 0.6,
													width: '70%',
													margin: '0 auto',
												}}
											>
												Email
											</Typography>
										</Box>
									</Button>
								</a>
							</Grid>
						</Grid>
					</Box>

					{/* <GradientSectionDivider sx={{ mb: 2, mt: 1, opacity: 0.2 }} /> */}
				</Box>
			</Box>
		</Container>
	);
};
