import { Box, Container, Grid, Typography, styled, Button } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleIcon from '@mui/icons-material/People';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import CloudIcon from '@mui/icons-material/Cloud';
import BuildIcon from '@mui/icons-material/Build';
import UpdateIcon from '@mui/icons-material/Update';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BugReportIcon from '@mui/icons-material/BugReport';
import CodeIcon from '@mui/icons-material/Code';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const ServiceCard = styled(Box)(() => ({
    width: '100%',
    minHeight: '220px',
    margin: '0 auto',
    padding: '1.5rem',
    boxSizing: 'border-box',
    borderRadius: '16px',
    backgroundColor: 'rgba(208, 255, 214, 0.02)',
    border: '1px solid rgba(208, 255, 214, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
}));

const SupportLevelCard = styled(Box)(({ theme }) => ({
    width: '100%',
    padding: '20px',
    borderRadius: '16px',
    backgroundColor: 'rgba(208, 255, 214, 0.02)', // Matching the ServiceCard background
    border: '1px solid rgba(208, 255, 214, 0.1)', // Matching the ServiceCard border
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    marginBottom: '20px',
    [theme.breakpoints.up('sm')]: {
        minHeight: '120px',
    },
}));

const SupportIcon = styled(Box)(({ theme }) => ({
    marginRight: '20px',
    color: '#2ECC71', // Dark green color
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        fontSize: '2rem',
    },
}));

const ContactSection = styled(Box)(({ theme }) => ({
    backgroundColor: '#2ECC71',
    padding: '40px 20px',
    textAlign: 'center',
    borderRadius: '16px',
    marginTop: '100px',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
}));

const ContactButton = styled(Button)(({ theme }) => ({
    backgroundColor: '#27AE60', // Darker shade of green
    color: '#FFF',
    padding: '10px 20px',
    fontSize: '1rem',
    fontWeight: 'bold',
    borderRadius: '8px',
    marginTop: '20px',
    '&:hover': {
        backgroundColor: '#F1C40F', // Dark yellow color on hover as well
    },
    textDecoration: 'none',
}));

export const Support = () => {
    return (
        <Container maxWidth="lg">
            <Box
                sx={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '90px',
                }}
            >
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: { xs: '2.5rem', sm: '4rem' },
                        fontWeight: 600,
                        lineHeight: 1.05,
                        color: '#FFF',
                        my: '24px',
                    }}
                >
                    Commercial Support
                </Typography>

                <Typography
                    variant="h5"
                    sx={{
                        fontSize: { xs: '1.125rem', sm: '1.375rem' },
                        color: 'rgba(255, 255, 255, 0.6)',
                        maxWidth: { xs: '80%', md: '65%' },
                        margin: '0 auto',
                        mb: '44px',
                    }}
                >
                    Our commercial support services are geared towards helping you have the best Triage AI
                    experience possible. We provide{' '}
                    <span style={{ color: '#D0FFD6', textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)' }}>
                        custom support packages
                    </span>{' '}
                    tailored to your business needs and budget. Having our Triage AI team{' '}
                    <span style={{ color: '#D0FFD6', textShadow: '0px 0px 8px rgba(46, 204, 113, 0.8)' }}>
                        on-hand
                    </span>{' '}
                    ensures you will get the most out of your help desk system.
                </Typography>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: '20px', // Reduced margin top
                }}
            >
                <Grid
                    container
                    spacing={4}
                    maxWidth="md"
                >
			<Grid
				xs={12}
				sm={6}
				item
			>
				<ServiceCard 
					sx={{ 
						minHeight: { sm: '350px', md: '260px' }, 
						'&:hover': { 
							boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
						},
						display: 'flex', // Center the items horizontally
						flexDirection: 'column', // Stack the items vertically
						justifyContent: 'space-between', // Align items vertically with space in between
						alignItems: 'center', // Center items horizontally
					}}
				>
					<SupervisedUserCircleIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
					<Typography
						variant="h6"
						sx={{
							fontWeight: 600,
							lineHeight: 1.3125,
							color: '#FFF',
							mb: 1,
							mt: 2,
						}}
					>
						Community Forum

					</Typography>

					<Typography
						variant="subtitle2"
						sx={{
							color: '#D0FFD6',
							mb: 2, // Increased margin bottom
							opacity: 0.6,
						}}
					>
						Come on in! Learn from your peers in the Triage AI Community Forum. This is the place to ask questions, learn, and help other Triage AI users.
					</Typography>

					<a href="https://discord.com/channels/1241429248451809430/1241429248451809436" style={{ textDecoration: 'none', alignSelf: 'center' }}>
						<Button 
							variant="outlined" 
							color="inherit" 
							sx={{ 
								color: '#D0FFD6', 
								borderColor: '#D0FFD6',
								'&:hover': { 
									color: '#FFF', 
									borderColor: '#FFF',
								},
							}}
						>
							Join Discord
						</Button>
					</a>
				</ServiceCard>
			</Grid>
                    <Grid
                        xs={12}
                        sm={6}
                        item
                    >
                       <ServiceCard 
					sx={{ 
						minHeight: { sm: '350px', md: '260px' }, 
						'&:hover': { 
							boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
						},
						display: 'flex', // Center the items horizontally
						flexDirection: 'column', // Stack the items vertically
						justifyContent: 'space-between', // Align items vertically with space in between
						alignItems: 'center', // Center items horizontally
					}}
				>
					<DescriptionIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
					<Typography
						variant="h6"
						sx={{
							fontWeight: 600,
							lineHeight: 1.3125,
							color: '#FFF',
							mb: 1,
							mt: 2,
						}}
					>
						Triage Al Documentation

					</Typography>

					<Typography
						variant="subtitle2"
						sx={{
							color: '#D0FFD6',
							mb: 2, // Increased margin bottom
							opacity: 0.6,
						}}
					>
						Our comprehensive documentation gives you all the knowledge you need to setup, configure, and get the most out of Triage Al.
					</Typography>

					<a href="https://github.com/rayandabbagh/triage.ai.backend/" style={{ textDecoration: 'none', alignSelf: 'center' }}>
						<Button 
							variant="outlined" 
							color="inherit" 
							sx={{ 
								color: '#D0FFD6', 
								borderColor: '#D0FFD6',
								'&:hover': { 
									color: '#FFF', 
									borderColor: '#FFF',
								},
							}}
						>
							More Details
						</Button>
					</a>
				</ServiceCard>
                    </Grid>
                </Grid>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    mt: '80px',
                }}
            > 
                <Typography
                    variant="h4"
                    sx={{
                        color: '#FFF',
                        mb: '40px',
                        fontWeight: 'bold',
                    }}
                >
                    Commercial Support Levels:
                </Typography>

				<SupportLevelCard>
					<SupportIcon>
						<ChatBubbleOutlineIcon sx={{ fontSize: 40 }} />
					</SupportIcon>
					<Box>
						<Typography
							variant="h6"
							sx={{
								fontWeight: 600,
								color: '#FFF',
								mb: 2,
							}}
						>
							Premium Support: $12,500/Year
						</Typography>
						<Typography
							variant="body1"
							sx={{
								color: '#FFF',
								opacity: 0.8,
							}}
						>
							Triage AI Premium Support provides the same level of service as our Standard Support but with unlimited phone and email support between 9:00a - 5:00p Central Time.
						</Typography>
						<a href="https://calendly.com/rayan-dabbagh/triage-ai" style={{ textDecoration: 'none', alignSelf: 'center', marginTop: '16px' }}>
						<Button 
							variant="outlined" 
							color="inherit" 
							sx={{ 
								color: '#D0FFD6', 
								borderColor: '#D0FFD6',
								padding: '4px 8px',
                    			marginTop: '16px', 
								'&:hover': { 
									color: '#FFF', 
									borderColor: '#FFF',
								},
							}}
						>
							More Details <ArrowForwardIcon sx={{ marginLeft: '8px' }} />
						</Button>
						</a>
					</Box>
				</SupportLevelCard>

                <SupportLevelCard>
                    <SupportIcon>
                        <CloudIcon sx={{ fontSize: 40 }} />
                    </SupportIcon>
                    <Box>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 600,
                                color: '#FFF',
                                mb: 1, // Added margin bottom
                            }}
                        >
                            Enterprise Support: $25,000/Year
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                color: '#FFF',
                                opacity: 0.8,
                            }}
                        >
                            Triage AI Enterprise Support provides Standard Business level support as well as 24/7 on-call developer support. This includes support with hosting infrastructure.
                        </Typography>
						<a href="https://calendly.com/rayan-dabbagh/triage-ai" style={{ textDecoration: 'none', alignSelf: 'center', marginTop: '16px' }}>
						<Button 
							variant="outlined" 
							color="inherit" 
							sx={{ 
								color: '#D0FFD6', 
								borderColor: '#D0FFD6',
								padding: '4px 8px',
                    			marginTop: '16px', 
								'&:hover': { 
									color: '#FFF', 
									borderColor: '#FFF',
								},
							}}
						>
							Get Support 24/7 <ArrowForwardIcon sx={{ marginLeft: '8px' }} />
						</Button>
						</a>
                    </Box>
                </SupportLevelCard>
            </Box>

            <ContactSection>
                <Typography
                    variant="h4"
                    sx={{
                        color: '#FFF',
                        mb: '20px',
                        fontWeight: 'bold',
                    }}
                >
                    Leverage Our Experience
                </Typography>
                <Typography
                    variant="h6"
                    sx={{
                        color: '#FFF',
                        mb: '20px',
                        fontWeight: 'bold',
                    }}
                >
                    Contact us today to get more information about our commercial support packages.
                </Typography>
                <a
                    href="mailto:support@triage-ai.com?cc=shivam.p36181@gmail.com,rayan.dabbagh@gmail.com&subject=[Triage AI Inquiry] *Please provide details about the purpose of your inquiry*"
                    style={{ textDecoration: 'none', width: 'fit-content' }}
                >
                    <ContactButton>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: '#FFF',
                                fontWeight: 700,
                                textTransform: 'none',
                                width: 'fit-content',
                                mr: 0.5,
                            }}
                        >
                            Contact us
                        </Typography>
                    </ContactButton>
                </a>
            </ContactSection>
			<Box
    sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: '120px', // Reduced margin top
        mb: '60px',
    }}
>
    <Grid
        container
        spacing={4}
        maxWidth="md"
    >
        <Grid
            xs={12}
            sm={6}
            item
        >
            <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' },  '&:hover': { 
                        boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
                    }  }}>
                <BuildIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        lineHeight: 1.3125,
                        color: '#FFF',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Production and Operational Support
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: '#D0FFD6',
                        mb: 0,
                        opacity: 0.6,
                    }}
                >
                    Our professional staff can help you get the most out of Triage AI by answering all of your “how-to” questions and providing instruction on best practices, usage, and implementation.
                </Typography>
            </ServiceCard>
        </Grid>

        <Grid
            xs={12}
            sm={6}
            item
        >
            <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' },  '&:hover': { 
                        boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
                    }  }}>
                <CodeIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        lineHeight: 1.3125,
                        color: '#FFF',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Best Practices And Performance Audits
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: '#D0FFD6',
                        mb: 0,
                        opacity: 0.6,
                    }}
                >
                    We’ll review your hosting environment and make settings recommendations for managing and optimizing your resources, ensuring your Triage AI installation runs smoothly, efficiently, and securely.
                </Typography>
            </ServiceCard>
        </Grid>
    </Grid>
</Box>
<Box
    sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: '20px', // Reduced margin top
        mb: '60px',
    }}
>
    <Grid
        container
        spacing={4}
        maxWidth="md"
    >
        <Grid
            xs={12}
            sm={6}
            item
        >
            <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' },  '&:hover': { 
                        boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
                    }  }}>
                <BugReportIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        lineHeight: 1.3125,
                        color: '#FFF',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Issue Resolution – Unlimited Incidents
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: '#D0FFD6',
                        mb: 0,
                        opacity: 0.6,
                    }}
                >
                    As a commercial support customer, you’re not limited to a certain number of support incidents per month. Our services are always available, any time you need them.
                </Typography>
            </ServiceCard>
        </Grid>

        <Grid
            xs={12}
            sm={6}
            item
        >
            <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' }, '&:hover': { 
                        boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
                    } }}>
                <PeopleIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        lineHeight: 1.3125,
                        color: '#FFF',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Access To Core Development Team
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: '#D0FFD6',
                        mb: 0,
                        opacity: 0.6,
                    }}
                >
                    Best of all, you’ll be working directly with Triage AI developers, and not third party support agents. Meaning you’ll be receiving the absolute best support possible.
                </Typography>
            </ServiceCard>
        </Grid>
    </Grid>
</Box>
<Box
    sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        mt: '20px', // Reduced margin top
    }}
>
    <Grid
        container
        spacing={4}
        maxWidth="md"
    >
        <Grid
            xs={12}
            sm={6}
            item
        >
            <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' },  '&:hover': { 
                        boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
                    }  }}>
                <UpdateIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        lineHeight: 1.3125,
                        color: '#FFF',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Proactive Updates And Patches Support
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: '#D0FFD6',
                        mb: 0,
                        opacity: 0.6,
                    }}
                >
                    We’ll keep you up to date with the latest updates and patches for Triage AI.
                </Typography>
            </ServiceCard>
        </Grid>

        <Grid
            xs={12}
            sm={6}
            item
        >
            <ServiceCard sx={{ minHeight: { sm: '350px', md: '260px' },  '&:hover': { 
                        boxShadow: '0px 0px 10px 2px rgba(208, 255, 214, 0.6)', // Increased shadow on hover
                    }  }}>
                <AccessTimeIcon sx={{ color: "#D0FFD6", fontSize: 40 }} />
                <Typography
                    variant="h6"
                    sx={{
                        fontWeight: 600,
                        lineHeight: 1.3125,
                        color: '#FFF',
                        mb: 1,
                        mt: 2,
                    }}
                >
                    Priority Response Times
                </Typography>

                <Typography
                    variant="subtitle2"
                    sx={{
                        color: '#D0FFD6',
                        mb: 0,
                        opacity: 0.6,
                    }}
                >
                    You get guaranteed response times based on service-level agreement, tailored to your needs and budget.
                </Typography>
            </ServiceCard>
        </Grid>
    </Grid>
</Box> </Container>
    );
};
